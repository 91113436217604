<template>
	<div class="app-container" v-loading="loading">
		<el-header class="new-header">
			<div class="new-header-left">商家列表</div>
			<div class="new-header-right">
				<el-button class="btn" @click="dialogForAddMerchant = true" type="primary" plain>新建商家</el-button>
			</div>
		</el-header>
		<el-form :inline="true" :model="searchForm" ref="searchForm" @keyup.enter.native="onSubmit">
			<el-form-item label="商家名称">
				<el-input v-model="searchForm.merchantName" placeholder="商家名称" size="small" clearable
					@clear="searchForm.merchantName = null"></el-input>
			</el-form-item>
			<el-form-item label="状态" prop="state">
				<el-select v-model="searchForm.state" clearable @clear="searchForm.state = null" placeholder="状态" size="small">
					<el-option label="启用" :value="1"> </el-option>
					<el-option label="停用" :value="0"> </el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit" icon="search" size="small">搜索</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="list" border style="width: 100%" ref="list" stripe size="small">
			<el-table-column prop="merchantId" label="ID" align="center"></el-table-column>
			<el-table-column prop="merchantName" label="商家名称" align="center"></el-table-column>
			<el-table-column prop="seatNumber" label="坐席上限数量" align="center"></el-table-column>
			<el-table-column prop="chargingTypeName" label="计费方式" align="center"></el-table-column>
			<el-table-column prop="ruleName" label="计费规则" align="center"></el-table-column>
			<el-table-column prop="acctName" label="计费账户" align="center"></el-table-column>
			<el-table-column prop="adminName" label="创建人名称" align="center"></el-table-column>
			<el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
			<el-table-column prop="stateName" label="状态" align="center"></el-table-column>
			<el-table-column prop="hasUsedAcctName" label="账户透支状态" align="center"></el-table-column>
			<el-table-column label="操作" align="center">
				<template slot-scope="scope">
					<el-tooltip placement="top" content="编辑">
						<el-button type="text" @click="handleInitModifyMerchant(scope.row)" size="small">
							编辑
						</el-button>
					</el-tooltip>
					<el-tooltip placement="top" content="删除">
						<el-button type="text" style="color: red" @click="handleDeleteMerchant(scope.row)" size="small">
							删除
						</el-button>
					</el-tooltip>
					<el-tooltip placement="top" content="查看商家">
						<el-button type="text" @click="handleViewDetails(scope.row)" size="small">
							查看
						</el-button>
					</el-tooltip>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination-block">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="pageVo.current" :page-sizes="[2, 10, 20, 50, 100]" :page-size="pageVo.size"
				layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
			</el-pagination>
		</div>
		<el-dialog title="新建商家" :visible.sync="dialogForAddMerchant">
			<el-form ref="addForm" :model="addForm" :rules="rules" label-width="110px">
				<el-row type="flex" justify="center">
					<el-col :span="12">
						<el-form-item label="商家名称:" prop="merchantName">
							<el-input v-model="addForm.merchantName" placeholder="商家名称"></el-input>
						</el-form-item>
						<el-form-item label="计费方式:" prop="chargingType">
							<el-select v-model="addForm.chargingType" placeholder="计费方式">
<!--								<el-option label="坐席计费" :value="1"></el-option>-->
								<el-option label="商家计费:" :value="2"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="计费规则:" prop="ruleId">
							<el-select v-model="addForm.ruleId" placeholder="计费规则" filterable>
								<el-option v-for="item in ruleList" :key="item.ruleId" :label="item.ruleName" :value="item.ruleId">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="计费账户:" prop="acctId">
							<el-select v-model="addForm.acctId" placeholder="计费账户" filterable>
								<el-option v-for="item in accountList" :key="item.acctId" :label="item.acctName" :value="item.acctId">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="坐席上限数量:" prop="seatNumber">
							<el-input-number controls-position="right" :min="1" v-model="addForm.seatNumber" placeholder="坐席上限数量">
							</el-input-number>
						</el-form-item>
            <el-form-item label="账户透支状态:" prop="hasUsedAcct">
              <el-select v-model="addForm.hasUsedAcct" placeholder="账户透支状态">
                <el-option label="不可透支" :value="0"></el-option>
                <el-option label="可透支" :value="1"></el-option>
              </el-select>
            </el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer">
				<el-button type="info" @click="dialogForAddMerchant = false">取消</el-button>
				<el-button type="primary" @click="handleAddMerchant">确认</el-button>
			</div>
		</el-dialog>
		<el-dialog title="编辑商家" :visible.sync="dialogForModifyMerchant">
			<el-form ref="modifyForm" :model="modifyForm" :rules="rules" label-width="110px">
				<el-row type="flex" justify="center">
					<el-col :span="12">
						<el-form-item label="商家名称:" prop="merchantName">
							<el-input v-model="modifyForm.merchantName" placeholder="商家名称"></el-input>
						</el-form-item>
						<el-form-item label="计费方式:" prop="chargingType">
							<el-select v-model="modifyForm.chargingType" placeholder="计费方式">
<!--								<el-option label="坐席计费" :value="1"></el-option>-->
								<el-option label="商家计费:" :value="2"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="计费规则:" prop="ruleId">
							<el-select v-model="modifyForm.ruleId" placeholder="计费规则" filterable>
								<el-option v-for="item in ruleList" :key="item.ruleId" :label="item.ruleName" :value="item.ruleId">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="计费账户:" prop="acctId">
							<el-select v-model="modifyForm.acctId" placeholder="计费账户" filterable>
								<el-option v-for="item in accountList" :key="item.acctId" :label="item.acctName" :value="item.acctId">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="坐席上限数量:" prop="seatNumber">
							<el-input-number controls-position="right" :min="1" v-model="modifyForm.seatNumber" placeholder="坐席上限数量">
							</el-input-number>
						</el-form-item>
						<el-form-item label="状态:" prop="state">
							<el-select v-model="modifyForm.state" clearable @clear="modifyForm.state = null" placeholder="状态"
								style="width: 100%">
								<el-option label="启用" :value="1"> </el-option>
								<el-option label="停用" :value="0"> </el-option>
							</el-select>
						</el-form-item>
            <el-form-item label="账户透支状态:" prop="hasUsedAcct">
              <el-select v-model="modifyForm.hasUsedAcct" placeholder="账户透支状态">
                <el-option label="不可透支" :value="0"></el-option>
                <el-option label="可透支" :value="1"></el-option>
              </el-select>
            </el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer">
				<el-button type="info" @click="dialogForModifyMerchant = false">取消</el-button>
				<el-button type="primary" @click="handleModifyMerchant">确认</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		createMerchant,
		deleteMerchant,
		editMerchant,
		getAccountRuleListNoPage,
		getMerchantListByPage,
		listBillingAcct,
	} from '../../api';

	export default {
		name: '',
		data() {
			return {
				dialogForAddMerchant: false,
				dialogForModifyMerchant: false,
				adminId: this.$store.getters.adminId,
				searchForm: {
					adminId: this.$store.getters.adminId,
					merchantName: null,
					state: null,
				},
				addForm: {
					ruleId: null,
					chargingType: 2,
					acctId: null,
					merchantName: '',
					seatNumber: 1,
					adminId: this.$store.getters.adminId,
          hasUsedAcct: 0
				},
				modifyForm: {
					merchantName: '',
					adminId: this.$store.getters.adminId,
					seatNumber: 1,
					state: null,
					ruleId: null,
					chargingType: 2,
					acctId: null,
          hasUsedAcct: null
				},
				list: [],
				accountList: [],
				ruleList: [],
				pageVo: {
					current: 1,
					size: 10,
				},
				totalCount: 0,
				loading: false,
				rules: {
					merchantName: [{ required: true, message: '请输入商家名称', trigger: 'blur' }],
					acctId: [{ required: true, message: '请选择计费账户', trigger: 'change' }],
					chargingType: [{ required: true, message: '请选择计费方式', trigger: 'change' }],
          state: [{ required: true, message: '请选择商家状态', trigger: 'change' }],
          hasUsedAcct: [{ required: true, message: '请选择账户透支状态', trigger: 'change' }],
          ruleId: [{ required: true, message: '请选择计费规则', trigger: 'change' }],
				},
			};
		},
		created() {
			this.handleInitSelections();
			this.loadMerchantList();
		},
		methods: {
			async handleInitSelections() {
				this.accountList = await listBillingAcct({ adminId: this.adminId });
				this.ruleList = await getAccountRuleListNoPage({ adminId: this.adminId });
			},
			handleViewDetails(row) {
				if (row.merchantId) {
					this.$router.push({
						name: 'merchant-details',
						query: {
							id: row.merchantId,
							name: row.merchantName,
						},
					});
				}
			},
			handleDeleteMerchant(row) {
				if (row.merchantId) {
					this.$confirm(`确定删除商家${row.merchantName}吗？`, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					}).then(() => {
						deleteMerchant({
							merchantId: row.merchantId,
							adminId: this.$store.getters.adminId,
						}).then((data) => {
							if (data) {
								this.$notify({
									type: 'success',
									title: '成功',
									message: '删除成功',
								});
								this.loadMerchantList();
							}
						});
					});
				}
			},
      handleAddMerchant() {
				this.$refs.addForm.validate((valid) => {
					if (valid) {
						let param = {
							...this.addForm,
						};
						createMerchant(param).then((data) => {
							if (data) {
								this.$notify({
									type: 'success',
									title: '成功',
									message: '创建商家成功',
								});
								this.dialogForAddMerchant = false;
								this.loadMerchantList();
							}
						});
					}
				});
			},
			handleInitModifyMerchant(row) {
				this.modifyForm.acctId = row.acctId;
				this.modifyForm.merchantName = row.merchantName;
				this.modifyForm.merchantId = row.merchantId;
				this.modifyForm.state = row.state ? Number(row.state) : null;
				this.modifyForm.ruleId = row.ruleId ? Number(row.ruleId) : null;
				this.modifyForm.chargingType = row.chargingType ? Number(row.chargingType) : null;
				this.modifyForm.seatNumber = row.seatNumber ? Number(row.seatNumber) : null;
        this.modifyForm.hasUsedAcct = row.hasUsedAcct;
				this.dialogForModifyMerchant = true;
			},
			handleModifyMerchant() {
				this.$refs.modifyForm.validate((valid) => {
					if (valid) {
						let param = {
							...this.modifyForm,
						};
						editMerchant(param).then((data) => {
							if (data) {
								this.$notify({
									type: 'success',
									title: '成功',
									message: '编辑商家成功',
								});
								this.dialogForModifyMerchant = false;
								this.loadMerchantList();
							}
						});
					}
				});
			},
			onSubmit() {
				this.pageVo.current = 1;
				this.loadMerchantList();
			},
			handleSizeChange(val) {
				this.pageVo.size = val;
				this.loadMerchantList();
			},
			handleCurrentChange(val) {
				this.pageVo.current = val;
				this.loadMerchantList();
			},
			loadMerchantList() {
				let params = {
					...this.pageVo,
					params: this.searchForm,
				};
				this.loading = true;
				getMerchantListByPage(params).then((data) => {
					console.log(data);
					this.list = data.records;
					this.totalCount = data.total;
					this.loading = false;
				});
			},
		},
	};
</script>
<style lang="scss"></style>
